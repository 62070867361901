<script lang="ts" setup>
import Mask from '~/enums/mask'

const modelValue = defineModel<number>()

const props = withDefaults(
  defineProps<{
    isSmall?: boolean
    isWideOnMobile?: boolean
    max?: number
    min?: number
    multiplicity?: number
  }>(),
  { isSmall: false, isWideOnMobile: false, max: 1000, min: 1, multiplicity: 1 }
)

const maskaOptions = { number: Mask.getMaskaOptions(Mask.Number) }

const fakeInput = ref<HTMLElement | null>(null)

const remainder = computed(() => modelValue.value! % props.multiplicity)

watch(modelValue, updateWidthFakeInput)

onMounted(updateWidthFakeInput)

function decreaseQuantity (): void {
  const deltaQuantity = remainder.value === 0 ? props.multiplicity : remainder.value

  updateValue(modelValue.value! - deltaQuantity)
}

function increaseQuantity (): void {
  const deltaQuantity = remainder.value === 0 ? props.multiplicity : props.multiplicity - remainder.value

  updateValue(modelValue.value! + deltaQuantity)
}

function handleBlur (): void {
  const valueAsNumber = Number(modelValue.value)
  const { min, max } = props

  if (isNaN(valueAsNumber) || valueAsNumber < min) {
    modelValue.value = min
  } else if (valueAsNumber > max) {
    modelValue.value = max
  }
}

function updateValue (x: number): void {
  if (x >= props.min && x <= props.max) {
    modelValue.value = x
  }
}

function updateWidthFakeInput (): void {
  if (fakeInput.value) {
    fakeInput.value.innerHTML = String(modelValue.value)
  }
}
</script>

<template>
  <div class="quantity-counter flex items-center bg-white" :class="{ small: isSmall, 'wide-on-mobile': isWideOnMobile }">
    <SvgIcon
      class="quantity-counter__minus cursor-pointer text-main-green"
      :class="{ disabled: modelValue === min }"
      name="outlined/remove"
      @click="decreaseQuantity"
    />

    <span class="quantity-counter__input-wrapper">
      <span ref="fakeInput" aria-hidden="true" class="fake-input" />
      <input v-model.number="modelValue" v-maska="maskaOptions.number" class="text-center" @blur="handleBlur">
    </span>

    <SvgIcon
      class="quantity-counter__plus cursor-pointer text-main-green"
      :class="{ disabled: modelValue === max }"
      name="outlined/add"
      @click="increaseQuantity"
    />
  </div>
</template>

<style lang="scss" scoped>
@import 'assets/css/variables';

.quantity-counter {
  border: 1px solid mix($main-gray-color, white, 20%);
  border-radius: 12px;
  box-sizing: border-box;
  height: 40px;
  padding: 10px 16px;
  width: fit-content;

  &.small {
    height: 24px;
    padding: 2px 0;

    .quantity-counter__input-wrapper {
      font-size: 16px;
      line-height: 18px;
      margin: 0 4px;
    }

    .quantity-counter__minus,
    .quantity-counter__plus {
      font-size: 16px;
      line-height: 18px;
    }
  }

  &.wide-on-mobile {
    @media screen and (max-width: 480px) {
      justify-content: space-between;
      width: 100% !important;
    }
  }

  &__input-wrapper {
    font-size: 18px;
    line-height: 22px;
    margin: 0 8px;
    position: relative;

    > .fake-input[aria-hidden='true'] {
      visibility: hidden;
    }

    > input {
      background: transparent;
      border: none;
      box-sizing: border-box;
      font-family: inherit;
      font-size: inherit;
      left: 0;
      line-height: inherit;
      outline: none;
      position: absolute;
      top: 0;
      width: 100%;
    }
  }

  &__minus,
  &__plus {
    font-size: 18px;
    line-height: 22px;
    padding: 0 2px;
    user-select: none;

    &.disabled {
      opacity: 0.35;
      cursor: auto;
    }
  }
}
</style>
